import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import Content from '../components/content';
import Layout from '../components/layout';
import Meta from '../components/meta';
import Skills from '../components/skills';

const Home = () => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            email
            linkedin
          }
        }
      }
    `
  );

  const { email, linkedin } = data.site.siteMetadata;

  return (
    <Layout>
      <Meta titleTemplate="%s" image="/images/og-image-hello.png" />

      <section>
        <Content>
          <h1>Hello!</h1>

          <p>
            I’m Sergei Kriger, frontend developer, accessibility specialist and occasional public speaker. Here are the
            things I do:
          </p>
        </Content>
      </section>

      <Skills />

      <section>
        <Content>
          <h2>Projects</h2>

          <p>
            <a target="_blank" rel="noreferrer" href="https://a11ymyths.com/">
              Accessibility Myths
            </a>
            <div>A small project debunking common accessibility myths.</div>
          </p>
        </Content>
      </section>

      <section>
        <Content>
          <h2>Contact</h2>
          <p>
            If you have any questions please send me an&nbsp;
            <a href={`mailto:${email}`}>email</a>. You can also find me on&nbsp;
            <a href={linkedin} target="_blank" rel="noreferrer">
              linkedin
            </a>
            .
          </p>
        </Content>
      </section>
    </Layout>
  );
};

export default Home;
