import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Icon from '../icon';

import './Skills.css';
import Content from '../content';

const Skills = () => {
  const data = useStaticQuery(
    graphql`
      query {
        allFile(
          filter: { sourceInstanceName: { eq: "skills" } }
          sort: { order: ASC, fields: childMarkdownRemark___frontmatter___order }
        ) {
          edges {
            node {
              id
              childMarkdownRemark {
                frontmatter {
                  title
                  icon
                }
                html
              }
            }
          }
        }
      }
    `
  );

  const { edges } = data.allFile;

  return edges.length ? (
    <section>
      <Content wide>
        <h2 className="visually-hidden">What I Do</h2>
        <div className="skills">
          {edges.map((skill) => {
            const { frontmatter, html } = skill.node.childMarkdownRemark;
            const { title, icon } = frontmatter;

            return (
              <div className="skill-card" key={title}>
                <Icon name={icon} className="skill-card__icon" />
                <h3 className="skill-card__headline">{title}</h3>
                <div className="skill-card__desc" dangerouslySetInnerHTML={{ __html: html }} />
              </div>
            );
          })}
        </div>
      </Content>
    </section>
  ) : null;
};

export default Skills;
